import * as React from "react"
import { useLocation } from "@reach/router"
import Masonry from "react-masonry-component"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"

import { TagManagerContext } from "../../core/contexts/tagManager"
import useWindowDimensions from "../../core/hooks/useWindowDimensions"
import TextButton from "../buttons/textButton"
import Card from "../card/card"

const masonryOptions = {
  gutter: 24,
  horizontalOrder: true,
  itemSelector: ".grid-item",
}

type HeroGridProps = {
  items: {
    title: string
    description: string
    btnLabel: string
    btnHref: string
  }[]
}

const HeroGrid = ({ items }: HeroGridProps) => {
  const { width } = useWindowDimensions()
  const [columnWidth, setColumnWidth] = React.useState(0)
  const masonryLayoutRef = React.useRef<any>(null)
  const location = useLocation()
  const tagManager = React.useContext(TagManagerContext)

  const onLinkClick = (item: { label: string; href: string }) => {
    tagManager?.onLinkClickEvent({
      link_type: "navigation",
      link_url: location.origin + item.href,
      link_name: item.label,
      link_location: "page",
    })
  }

  React.useEffect(() => {
    const layoutWidth = masonryLayoutRef.current.masonry.containerWidth
    const layoutGutter = masonryLayoutRef.current.masonry.gutter
    const calculatedColumnWidth = calculateColumnWidth(
      width,
      layoutWidth,
      layoutGutter
    )
    setColumnWidth(calculatedColumnWidth)
  }, [width])

  const calculateColumnWidth = (
    width: number,
    layoutWidth: number,
    layoutGutter: number
  ) => {
    let columns
    if (width < 720) {
      columns = 1
    } else if (width < 960) {
      columns = 2
    } else {
      columns = 3
    }

    return (layoutWidth - layoutGutter * (columns - 1)) / columns
  }

  return (
    <Masonry className="mt-2" ref={masonryLayoutRef} options={masonryOptions}>
      {items.map(item => (
        <div
          key={item.title}
          className="grid-item mb-3"
          style={{ width: columnWidth }}
          onClick={() =>
            onLinkClick({
              label: item.btnLabel,
              href: item.btnHref,
            })
          }
        >
          <Card href={item.btnHref}>
            <h6 className="subtitle2">{item.title}</h6>
            <p>{item.description}</p>
            <TextButton icon={faArrowRight} label={item.btnLabel} />
          </Card>
        </div>
      ))}
    </Masonry>
  )
}

export default HeroGrid
