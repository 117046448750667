import * as React from "react"
import { Link } from "gatsby"

import "./card.scss"

type CardProps = {
  children: React.ReactNode
  onCardClick?: React.MouseEventHandler<HTMLDivElement>
  href?: string
  isExternalLink?: boolean
  canHover?: boolean
  isPrimary?: boolean
  isPadded?: boolean
}

const Card = ({
  children,
  onCardClick,
  href,
  isExternalLink = false,
  canHover = true,
  isPrimary = false,
  isPadded = true,
}: CardProps) => {
  const content = (
    <div
      className={`card ${canHover ? "card-hover" : "card-no-hover"} ${
        isPrimary ? "bg-primary text-white" : "bg-white text-black"
      }  ${isPadded ? "p-2" : "p-0"}`}
      onClick={onCardClick}
    >
      {children}
    </div>
  )

  if (href && href.length > 0) {
    // Has href, must be a link
    const attributes = isExternalLink
      ? { target: "_blank", rel: "noreferrer" }
      : {}

    if (isExternalLink) {
      return (
        <a href={href} {...attributes}>
          {content}
        </a>
      )
    } else if (href.startsWith("tel:") || href.startsWith("mailto:")) {
      return <a href={href}>{content}</a>
    } else {
      return <Link to={href}>{content}</Link>
    }
  } else {
    // Has no href, can't be a link
    return content
  }
}

export default Card
