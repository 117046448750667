import * as React from "react"

import Layout from "../components/layout/layout"
import Seo from "../components/seo/seo"
import HeroGrid from "../components/grid/heroGrid"
import Divider from "../components/divider/divider"

const heroItems = [
  {
    title: "Optimizing Your Sales and Marketing",
    description: "An accelerated approach to optimizing digital revenue.",
    btnLabel: "Explore Optimizations",
    btnHref: "/solutions/optimizing-your-sales-and-marketing",
  },
  {
    title: "Creating a Content Ecosystem",
    description:
      "Create collections of content which inform and entertain, but also persuade and ultimately drive the behaviours that align with business outcomes.",
    btnLabel: "Explore Content Creation",
    btnHref: "/solutions/creating-a-content-ecosystem",
  },
  {
    title: "Developing New Digital Products",
    description:
      "Connecting the user, the designer, the business, and the developer to invent better digital products and experiences.",
    btnLabel: "Explore Digital Products",
    btnHref: "/solutions/developing-new-digital-products",
  },
]

const description =
  "Get consultation on our digital transformation solutions for Sales and Marketing, Content, and Products. Why? Need optimized digital revenue? Need informative, entertaining, and business-aligned content? Need connected digital products and experiences?"

const SolutionsPage = () => {
  return (
    <Layout>
      <Seo title="Solutions" description={description} />
      <section className="container mt-5">
        <div className="mw-800">
          <h4>Solutions</h4>
          <p className="subtitle1">{description}</p>
        </div>
        <Divider />
        <div className="mt-5 mb-5 pt-5 pb-5">
          <HeroGrid items={heroItems} />
        </div>
      </section>
    </Layout>
  )
}

export default SolutionsPage
